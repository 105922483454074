import React, { useState, useEffect } from 'react';
import { Box, Grid, Card, CardContent, Typography, Divider } from '@mui/material';

const opinionsData = [
  { name: 'Juan Pérez', date: '15 de agosto de 2023', location: 'Quito, Ecuador', comment: 'Excelente producto, ha mejorado mi salud notablemente.' },
  { name: 'María Gómez', date: '22 de julio de 2023', location: 'Guayaquil, Ecuador', comment: 'La proteína de Sacha Inchi es increíble. Me siento con más energía.' },
  { name: 'Carlos Torres', date: '1 de septiembre de 2023', location: 'Cuenca, Ecuador', comment: 'El aceite es perfecto para mi dieta, me encanta el sabor.' },
  { name: 'Sofía Ramírez', date: '5 de agosto de 2023', location: 'Loja, Ecuador', comment: 'Muy satisfecha con los productos. Los recomiendo al 100%.' },
  { name: 'Pedro Martínez', date: '10 de octubre de 2023', location: 'Ambato, Ecuador', comment: 'Calidad y buen precio. Mis felicitaciones a Sainza.' }
];

const Opinions = () => {
  const [currentOpinions, setCurrentOpinions] = useState(opinionsData.slice(0, 3)); // Mostrar las primeras 3 opiniones

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentOpinions((prevOpinions) => {
        const startIndex = opinionsData.indexOf(prevOpinions[0]) + 3;
        return opinionsData.slice(startIndex, startIndex + 3).length > 0
          ? opinionsData.slice(startIndex, startIndex + 3)
          : opinionsData.slice(0, 3); // Reiniciar cuando llegues al final
      });
    }, 10000); // Cambiar opiniones cada 10 segundos

    return () => clearInterval(interval); // Limpiar el intervalo al desmontar el componente
  }, []);

  return (
    <Box sx={{ padding: '50px 20px', backgroundColor: '#f4f4f4', textAlign: 'center' }}> {/* Fondo gris claro */}
      <Typography 
        variant="h4" 
        gutterBottom 
        sx={{ 
          fontWeight: 'bold', 
          color: '#2fa68e',
          background: 'linear-gradient(to right, #2fa68e, #28a074)',  // Gradiente en el título
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent'
        }}
      >
        Opiniones de nuestros clientes
      </Typography>

      <Divider sx={{ width: { xs: '70%', md: '50%' }, margin: '20px auto', borderColor: '#2fa68e' }} />

      <Grid container spacing={4} justifyContent="center" sx={{ marginTop: '20px' }}>
        {currentOpinions.map((opinion, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card 
              sx={{ 
                height: '100%', 
                display: 'flex', 
                flexDirection: 'column', 
                justifyContent: 'center', 
                backgroundColor: '#fff', 
                borderRadius: '16px', // Bordes más redondeados
                boxShadow: '0 6px 15px rgba(0, 0, 0, 0.15)', // Sombra más suave
                transition: 'transform 0.3s, box-shadow 0.3s', // Animación en hover
                '&:hover': {
                  transform: 'scale(1.05)', // Efecto de zoom al hacer hover
                  boxShadow: '0 8px 20px rgba(0, 0, 0, 0.3)', // Sombra más profunda
                }
              }}
            >
              <CardContent>
                <Typography 
                  variant="h6" 
                  sx={{ 
                    fontWeight: 'bold', 
                    color: '#333', 
                    marginBottom: '8px' 
                  }}
                >
                  {opinion.name}
                </Typography>
                <Typography 
                  variant="body2" 
                  color="textSecondary" 
                  sx={{ marginBottom: '10px', color: '#777' }}
                >
                  {opinion.date} - {opinion.location}
                </Typography>
                <Typography variant="body1" sx={{ color: '#555', fontStyle: 'italic' }}>
                  "{opinion.comment}"
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Opinions;
