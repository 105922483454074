import React, { useState, useEffect } from 'react';
import { Box, Typography, Grid, IconButton, Modal, Collapse, useMediaQuery } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import Header from './Header';
import img1 from '../image/galeria/1.jpg'; 
import img2 from '../image/galeria/2.jpg';
import img3 from '../image/galeria/3.jpg';
import img4 from '../image/galeria/4.jpg';
import img5 from '../image/galeria/5.png';
import img6 from '../image/galeria/6.jpg';
import img7 from '../image/galeria/7.jpg';
import img8 from '../image/galeria/8.jpg';
import img9 from '../image/galeria/9.jpg';
import img10 from '../image/galeria/10.jpg';
import img11 from '../image/galeria/11.jpg';
import img12 from '../image/galeria/12.jpg';
import img13 from '../image/galeria/13.jpg';
import img14 from '../image/galeria/14.jpg';
import img15 from '../image/galeria/15.jpg';
import img16 from '../image/galeria/16.jpg';
import img17 from '../image/galeria/17.jpg';
import img18 from '../image/galeria/18.jpg';
import img19 from '../image/galeria/19.jpg';

const allImages = [
  { src: img1, section: 'Día 1' }, { src: img2, section: 'Día 1' }, { src: img3, section: 'Día 1' }, { src: img4, section: 'Día 1' },
  { src: img5, section: 'Día 2' }, { src: img6, section: 'Día 2' }, { src: img7, section: 'Día 2' }, { src: img8, section: 'Día 2' },
  { src: img9, section: 'Día 3' }, { src: img10, section: 'Día 3' }, { src: img11, section: 'Día 3' }, { src: img12, section: 'Día 3' },
  { src: img13, section: 'Día 4' }, { src: img14, section: 'Día 4' }, { src: img15, section: 'Día 4' }, { src: img16, section: 'Día 4' },
  { src: img17, section: 'Día 5' }, { src: img18, section: 'Día 5' }, { src: img19, section: 'Día 5' }
];

// Helper function to group images by section
const groupImagesBySection = (images) => {
  return images.reduce((sections, image) => {
    const section = sections.find(sec => sec.title === image.section);
    if (section) {
      section.images.push(image.src);
    } else {
      sections.push({ title: image.section, images: [image.src] });
    }
    return sections;
  }, []);
};

const groupedImages = groupImagesBySection(allImages);

const GalleryFull = () => {
  const [openModal, setOpenModal] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [openSections, setOpenSections] = useState({}); // Track which sections are open
  const isSmallScreen = useMediaQuery('(max-width: 600px)'); // Media query for small screens

  // Set sections to be collapsed or open depending on screen size
  useEffect(() => {
    const initialOpenState = {};
    groupedImages.forEach((section) => {
      initialOpenState[section.title] = !isSmallScreen; // Open on large screens, collapse on small
    });
    setOpenSections(initialOpenState);
  }, [isSmallScreen]);

  const handleImageClick = (index) => {
    setCurrentImageIndex(index);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handlePrevImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex === 0 ? allImages.length - 1 : prevIndex - 1));
  };

  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex === allImages.length - 1 ? 0 : prevIndex + 1));
  };

  const toggleSection = (section) => {
    setOpenSections((prevState) => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  };

  return (
    <>
      <Header
        scrollToAbout={() => { window.location.href = '/#about'; }}
        scrollToBenefits={() => { window.location.href = '/#benefits'; }}
        scrollToGallery={() => { window.location.href = '/#gallery'; }}
        scrollToProducts={() => { window.location.href = '/#products'; }}
        scrollToContact={() => { window.location.href = '/#contact'; }}
        scrollToOpinions={() => { window.location.href = '/#opinions'; }}
      />

      {/* Galería con fondo oscuro */}
      <Box sx={{ padding: '60px 20px', textAlign: 'center', backgroundColor: '#121212', color: '#e0e0e0' }}>
        <Typography
          variant="h3"
          sx={{ fontFamily: 'Serif, serif', marginBottom: '30px', color: '#2fa68e' }}
        >
          COMUNIDAD
        </Typography>
        {groupedImages.map((section, sectionIndex) => (
          <Box key={sectionIndex} sx={{ marginBottom: '40px', padding: '20px' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '10px' }}>
              <Typography
                variant="h5"
                sx={{ fontWeight: 'bold', textAlign: 'left', color: '#2fa68e' }}
              >
                {section.title}
              </Typography>
              <IconButton onClick={() => toggleSection(section.title)} sx={{ color: '#2fa68e' }}>
                {openSections[section.title] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </IconButton>
            </Box>
            <Collapse in={openSections[section.title]}>
              <Grid container spacing={4} justifyContent="center">
                {section.images.map((image, index) => (
                  <Grid item xs={12} sm={6} md={3} key={index}>
                    <Box
                      component="img"
                      src={image}
                      alt={`Galería ${index}`}
                      onClick={() => handleImageClick(index)}
                      sx={{
                        width: '100%',
                        borderRadius: '15px',
                        cursor: 'pointer',
                        boxShadow: '0 4px 10px rgba(0, 0, 0, 0.3)',
                        transition: 'transform 0.3s, box-shadow 0.3s',
                        '&:hover': {
                          transform: 'scale(1.05)',
                          boxShadow: '0 6px 12px rgba(47, 166, 142, 0.5)', // Destello verde claro
                        }
                      }}
                    />
                  </Grid>
                ))}
              </Grid>
            </Collapse>
          </Box>
        ))}
      </Box>

      {/* Modal para ver la imagen en grande */}
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Box sx={{ position: 'relative', width: '80%', maxWidth: '800px', textAlign: 'center' }}>
          <Box
            component="img"
            src={allImages[currentImageIndex].src}
            alt={`Imagen ampliada ${currentImageIndex}`}
            sx={{ width: '100%', borderRadius: '10px', boxShadow: '0 4px 10px rgba(0, 0, 0, 0.3)' }}
          />

          {/* Botón cerrar */}
          <IconButton
            onClick={handleCloseModal}
            sx={{
              position: 'absolute',
              top: 16,
              right: 16,
              color: 'white',
              backgroundColor: 'rgba(0,0,0,0.5)',
            }}
          >
            <CloseIcon />
          </IconButton>

          {/* Botón de navegación izquierda */}
          <IconButton
            onClick={handlePrevImage}
            sx={{
              position: 'absolute',
              top: '50%',
              left: 16,
              transform: 'translateY(-50%)',
              color: 'white',
              backgroundColor: 'rgba(0,0,0,0.5)',
            }}
          >
            <ArrowBackIosIcon />
          </IconButton>

          {/* Botón de navegación derecha */}
          <IconButton
            onClick={handleNextImage}
            sx={{
              position: 'absolute',
              top: '50%',
              right: 16,
              transform: 'translateY(-50%)',
              color: 'white',
              backgroundColor: 'rgba(0,0,0,0.5)',
            }}
          >
            <ArrowForwardIosIcon />
          </IconButton>
        </Box>
      </Modal>
    </>
  );
};

export default GalleryFull;
