import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, Button, IconButton, Box, Drawer, List, ListItem, ListItemText } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Link } from 'react-router-dom'; // Importar Link para la navegación
import logo from '../image/logo.png'; // Asegúrate de que esta ruta sea correcta

const Header = ({ scrollToAbout, scrollToBenefits, scrollToContact, scrollToProducts, scrollToOpinions }) => { // Añadimos scrollToOpinions
  const [drawerOpen, setDrawerOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  const handleMenuClick = (scrollFunction) => {
    scrollFunction();
    setDrawerOpen(false); // Cierra el menú hamburguesa después de hacer clic
  };

  const menuItems = (
    <List>
      <ListItem button onClick={() => handleMenuClick(scrollToAbout)}>
        <ListItemText primary="Conócenos" />
      </ListItem>
      <ListItem button onClick={() => handleMenuClick(scrollToBenefits)}>
        <ListItemText primary="Beneficios" />
      </ListItem>
      <ListItem button>
        <Link to="/gallery-full" style={{ textDecoration: 'none', color: 'inherit' }}>
          <ListItemText primary="Galería" />
        </Link>
      </ListItem>
      <ListItem button onClick={() => handleMenuClick(scrollToProducts)}>
        <ListItemText primary="Productos" />
      </ListItem>
      <ListItem button onClick={() => handleMenuClick(scrollToOpinions)}> {/* Opción de Opiniones */}
        <ListItemText primary="Opiniones" />
      </ListItem>
      <ListItem button onClick={() => handleMenuClick(scrollToContact)}>
        <ListItemText primary="Contacto" />
      </ListItem>
    </List>
  );

  return (
    <>
      <AppBar position="sticky" sx={{ backgroundColor: '#28a074', boxShadow: 'none' }}>
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
          {/* Logo en lugar de "Sainza Ecuador" */}
          <Link to="/" style={{ textDecoration: 'none' }}>
            <Box
              component="img"
              src={logo}
              alt="Logo"
              sx={{ width: '150px', cursor: 'pointer' }} // Tamaño ajustado del logo
            />
          </Link>

          {isMobile ? (
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={toggleDrawer(true)}
              sx={{
                backgroundColor: 'white',
                color: '#daeb6d',
                borderRadius: '50%',
                padding: '10px',
                marginLeft: '-10px',
              }}
            >
              <MenuIcon />
            </IconButton>
          ) : (
            <Box sx={{ display: 'flex', gap: '20px' }}>
              <Button color="inherit" onClick={scrollToAbout}>Conócenos</Button>
              <Button color="inherit" onClick={scrollToBenefits}>Beneficios</Button>
              <Button component={Link} to="/gallery-full" color="inherit">Galería</Button> {/* Redirigir a /gallery-full */}
              <Button color="inherit" onClick={scrollToProducts}>Productos</Button>
              <Button color="inherit" onClick={scrollToOpinions}>Opiniones</Button> {/* Opción de Opiniones */}
              <Button color="inherit" onClick={scrollToContact}>Contacto</Button>
            </Box>
          )}
        </Toolbar>
      </AppBar>

      <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
        {menuItems}
      </Drawer>
    </>
  );
};

export default Header;
