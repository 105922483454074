import React from 'react';
import { Box, Typography, Link, Grid } from '@mui/material';

const Footer = () => {
  return (
    <Box
      sx={{
        backgroundColor: '#2e2e2e',
        color: '#fff',
        padding: '20px 0',
        textAlign: 'center',
        bottom: 0,
        width: '100%',
      }}
    >
      <Grid container justifyContent="center" spacing={2}>
        <Grid item>
          <Link href="#home" color="inherit" underline="hover" sx={{ marginRight: '15px' }}>
            Sainza
          </Link>
        </Grid>
        <Grid item>
          <Link
            href="https://www.facebook.com/profile.php?id=61553831551408"
            target="_blank"
            color="inherit"
            underline="hover"
            sx={{ marginRight: '15px' }}
          >
            Facebook
          </Link>
        </Grid>
        <Grid item>
          <Link
            href="https://www.instagram.com/sainza.altonangaritza/"
            target="_blank"
            color="inherit"
            underline="hover"
          >
            Instagram
          </Link>
        </Grid>
      </Grid>
      
      <Typography variant="body2" sx={{ marginTop: '20px', fontSize: '14px' }}>
        <Link href="https://alfredoloai05.github.io/CV/" target="_blank" color="inherit" underline="none">
          © 2024. Desarrollado por Alfredo Loaiza / Loja-Ecuador 0991697973
        </Link>
      </Typography>
    </Box>
  );
};

export default Footer;
