import React from 'react';
import { Container, Typography, Button, Box } from '@mui/material';
import portada from '../image/portada.jpg'; 
const Hero = ({ scrollToAbout }) => {
  return (
    <Box
      sx={{
        backgroundImage: `url(${portada})`,
        backgroundSize: { xs: 'auto 300px', md: '510px 300px' }, 
        backgroundRepeat: { xs: 'repeat-y', md: 'repeat' }, 
        backgroundPosition: { xs: 'center top', md: 'top left' }, 
        height: { xs: '100vh', md: '600px' },
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#fff',
        textAlign: 'center',
        position: 'relative',
        padding: { xs: '0 20px', md: '0' }, 
      }}
    >
      {/* Fondo oscuro superpuesto */}
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.5)', 
        }}
      />
      
      {/* Contenido centrado */}
      <Container maxWidth="lg" sx={{ zIndex: 1 }}>
        <Typography
          variant="h2"
          gutterBottom
          sx={{
            fontSize: { xs: '32px', md: '48px' }, 
            fontWeight: 'bold',
          }}
        >
          Bienvenido a <span style={{ color: '#2fa68e' }}>Sainza</span>
        </Typography>
        <Box>
          <Button
            variant="contained"
            size="large"
            sx={{
              marginTop: '20px',
              backgroundColor: '#2fa68e',
              padding: { xs: '10px 20px', md: '15px 30px' }, 
              fontSize: { xs: '16px', md: '20px' },
              '&:hover': {
                backgroundColor: '#28a074',
              },
            }}
            onClick={scrollToAbout} 
          >
            Conócenos
          </Button>
        </Box>
      </Container>
    </Box>
  );
};

export default Hero;
