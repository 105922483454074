import React from 'react';
import { Box, Grid, TextField, Button, Typography, useMediaQuery, Paper, Divider } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const Contact = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box
      sx={{
        padding: '40px 20px', // Ajustar el padding para darle más aire
        backgroundColor: '#f9f9f9', // Fondo claro y elegante
        minHeight: '60vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Grid container spacing={4} justifyContent="center" alignItems="center">
        <Grid item xs={12} md={6}>
          {/* Encabezado del formulario */}
          <Box sx={{ textAlign: 'center', marginBottom: '30px' }}>
            <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#2fa68e', mb: 1 }}>
              Escríbenos
            </Typography>
            <Divider sx={{ width: { xs: '70%', md: '50%' }, margin: '20px auto', borderColor: '#2fa68e' }} />
          </Box>

          {/* Formulario */}
          <Paper sx={{ padding: isSmallScreen ? '20px' : '40px', borderRadius: '20px', boxShadow: '0 8px 20px rgba(0, 0, 0, 0.1)' }} elevation={4}>
            <form action="https://api.web3forms.com/submit" method="POST">
              <input type="hidden" name="access_key" value="c27ddbd6-f2d8-4659-affb-31c3cd0f4d2b" />

              <TextField
                fullWidth
                name="Nombre"
                label="Nombre"
                required
                variant="outlined"
                sx={{
                  mb: 3,
                  backgroundColor: '#fff',
                  borderRadius: '10px',
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: '#2fa68e', // Color del borde por defecto
                    },
                    '&:hover fieldset': {
                      borderColor: '#28a074', // Color del borde al hacer hover
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#2fa68e', // Color del borde al hacer foco
                    }
                  }
                }}
              />
              <TextField
                fullWidth
                name="Email"
                label="Correo"
                required
                variant="outlined"
                type="email"
                sx={{
                  mb: 3,
                  backgroundColor: '#fff',
                  borderRadius: '10px',
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: '#2fa68e',
                    },
                    '&:hover fieldset': {
                      borderColor: '#28a074',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#2fa68e',
                    }
                  }
                }}
              />
              <TextField
                fullWidth
                name="Telefono"
                label="Teléfono"
                required
                variant="outlined"
                type="tel"
                sx={{
                  mb: 3,
                  backgroundColor: '#fff',
                  borderRadius: '10px',
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: '#2fa68e',
                    },
                    '&:hover fieldset': {
                      borderColor: '#28a074',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#2fa68e',
                    }
                  }
                }}
              />
              <TextField
                fullWidth
                name="Mensaje"
                label="Mensaje"
                multiline
                rows={4}
                required
                variant="outlined"
                sx={{
                  mb: 3,
                  backgroundColor: '#fff',
                  borderRadius: '10px',
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: '#2fa68e',
                    },
                    '&:hover fieldset': {
                      borderColor: '#28a074',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#2fa68e',
                    }
                  }
                }}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  background: 'linear-gradient(90deg, #2fa68e 0%, #28a074 100%)', // Gradiente en el botón
                  color: '#fff',
                  borderRadius: '30px',
                  padding: '12px 0',
                  fontSize: '16px',
                  textTransform: 'none',
                  transition: 'background 0.3s ease-in-out',
                  '&:hover': {
                    background: 'linear-gradient(90deg, #28a074 0%, #249066 100%)', // Cambiar gradiente al hacer hover
                  }
                }}
              >
                Enviar &rarr;
              </Button>
            </form>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Contact;
