import React, { useState } from 'react';
import { Grid, Box, Typography, Card, CardContent, Collapse, Divider } from '@mui/material';
import corazon from '../image/corazon.png';
import cerebro from '../image/cerebro.png';
import inmunologico from '../image/inmunologico.png';
import gastrointestinal from '../image/gastrointestinal.png';
import cabello from '../image/cabello.png';
import piel from '../image/piel.png';

const benefitsData = [
  { title: 'Corazón', description: 'Mantén tu corazón fuerte y saludable, reduce el riesgo de enfermedades cardiovasculares.', image: corazon },
  { title: 'Cerebro', description: 'Alimenta tu mente y mejora tu función cognitiva.', image: cerebro },
  { title: 'Sistema Inmunológico', description: 'Fortalece tus defensas naturales con un estilo de vida saludable.', image: inmunologico },
  { title: 'Sistema Digestivo', description: 'Cuida tu sistema digestivo, el pilar de una buena salud.', image: gastrointestinal },
  { title: 'Cabello', description: 'Luce un cabello radiante y fuerte incorporando alimentos ricos en proteínas.', image: cabello },
  { title: 'Piel', description: 'Revela la belleza de tu piel cuidándola desde dentro y por fuera.', image: piel },
];

const Benefits = () => {
  const [openIndex, setOpenIndex] = useState(null); // Controlar el índice de la tarjeta abierta

  const handleToggle = (index) => {
    setOpenIndex(openIndex === index ? null : index); // Abrir o cerrar la tarjeta seleccionada
  };

  return (
    <Box sx={{ padding: '40px 20px', textAlign: 'center', maxWidth: '1200px', margin: '0 auto' }}>
      <Typography variant="h4" gutterBottom sx={{ color: '#2fa68e', fontWeight: 'bold' }}>
        Sacha Inchi
      </Typography>
      <Typography variant="h6" gutterBottom>
        Por su alto contenido en Omega 3,6 y 9 fortalece
      </Typography>

      <Divider sx={{ width: { xs: '70%', md: '50%' }, margin: '20px auto' }} />

      {/* Diseño para pantallas pequeñas: Íconos en dos filas de tres columnas */}
      <Box sx={{ display: { xs: 'block', md: 'none' } }}>
        <Grid container spacing={2} justifyContent="center">
          {benefitsData.map((benefit, index) => (
            <Grid item xs={4} key={index}>
              <Box
                onClick={() => handleToggle(index)}
                sx={{
                  textAlign: 'center',
                  cursor: 'pointer',
                  padding: '10px',
                }}
              >
                <Box
                  sx={{
                    width: '80px',
                    height: '80px',
                    borderRadius: '50%',
                    backgroundColor: openIndex === index ? '#d8e86d' : '#46b09b',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    margin: '0 auto',
                    transition: 'background-color 0.3s',
                  }}
                >
                  <img src={benefit.image} alt={benefit.title} style={{ width: '50px', height: '50px' }} />
                </Box>
                <Typography variant="caption" sx={{ color: '#fff', marginTop: '10px', display: 'block' }}>
                  {benefit.title}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>

        {/* Mostrar la descripción debajo del grupo de íconos seleccionados */}
        {openIndex !== null && (
          <Box
            sx={{
              backgroundColor: '#d8e86d',
              padding: '15px',
              borderRadius: '10px',
              marginTop: '20px',
              textAlign: 'center',
              transition: 'all 0.3s ease',
            }}
          >
            <Typography variant="body1" sx={{ color: '#333' }}>
              {benefitsData[openIndex].description}
            </Typography>
          </Box>
        )}
      </Box>

      {/* Diseño para pantallas grandes: opacar las demás tarjetas al abrir una */}
      <Grid container spacing={4} justifyContent="center" sx={{ marginTop: '30px', display: { xs: 'none', md: 'flex' } }}>
        {benefitsData.map((benefit, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card
              onClick={() => handleToggle(index)} // Controlar el clic
              onMouseEnter={() => handleToggle(index)} // Mostrar descripción en hover
              onMouseLeave={() => handleToggle(null)} // Ocultar la descripción al salir del hover
              sx={{
                borderRadius: '10px',
                boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
                padding: '15px',
                textAlign: 'center',
                cursor: 'pointer',
                backgroundColor: openIndex === index ? '#d8e86d' : '#46b09b', // Cambiar color al abrir
                opacity: openIndex !== null && openIndex !== index ? 0.5 : 1, // Opacar las otras tarjetas
                transition: 'transform 0.3s, background-color 0.3s, opacity 0.3s',
                '&:hover': {
                  transform: 'translateY(-5px)',
                },
                width: '100%', // Ajustar el ancho de la tarjeta
                maxWidth: '230px', // Limitar el ancho de la tarjeta
                margin: '0 auto', // Centrar la tarjeta en la columna
                height: '270px', // Altura fija para todas las tarjetas
                overflow: 'hidden', // Evitar que el contenido se desborde
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center', // Mantener contenido centrado verticalmente
                alignItems: 'center',
              }}
            >
              <Box
                sx={{
                  width: '70px',
                  height: '70px',
                  borderRadius: '50%',
                  backgroundColor: '#fff',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <img src={benefit.image} alt={benefit.title} style={{ width: '50px', height: '50px' }} />
              </Box>
              <CardContent
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: openIndex === index ? 'flex-start' : 'center',
                  transition: 'all 0.3s ease',
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: 'bold',
                    marginTop: '10px',
                    color: '#fff',
                    fontSize: '14px',
                    transform: openIndex === index ? 'translateY(-10px)' : 'none',
                  }}
                >
                  {benefit.title}
                </Typography>
                {/* Mostrar la descripción con hover en pantallas grandes o clic en pantallas pequeñas */}
                <Collapse in={openIndex === index} timeout="auto" unmountOnExit>
                  <Typography
                    variant="body2"
                    sx={{
                      marginTop: '10px',
                      color: '#333',
                      fontSize: '12px',
                      textAlign: 'center',
                    }}
                  >
                    {benefit.description}
                  </Typography>
                </Collapse>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Benefits;
