import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid, useMediaQuery, Divider } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles'; // Usar el tema de Material-UI

// Importar imágenes
import img1 from '../image/galeria/1.jpg'; 
import img2 from '../image/galeria/2.jpg';
import img3 from '../image/galeria/3.jpg';
import img4 from '../image/galeria/4.jpg';
import img5 from '../image/galeria/5.png';
import img6 from '../image/galeria/6.jpg';
import img7 from '../image/galeria/7.jpg';
import img8 from '../image/galeria/8.jpg';
import img9 from '../image/galeria/9.jpg';
import img10 from '../image/galeria/10.jpg';
import img11 from '../image/galeria/11.jpg';
import img12 from '../image/galeria/12.jpg';
import img13 from '../image/galeria/13.jpg';
import img14 from '../image/galeria/14.jpg';
import img15 from '../image/galeria/15.jpg';
import img16 from '../image/galeria/16.jpg';
import img17 from '../image/galeria/17.jpg';
import img18 from '../image/galeria/18.jpg';
import img19 from '../image/galeria/19.jpg';


const allImages = [img1, img2, img3, img4, img5, img6, img7, img8, , img9, img10, img11, img12, img13, img14, img15, img16, img17, img18, img19];

const Gallery = () => {
  const [visibleImages, setVisibleImages] = useState([img1, img2, img3]);

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm')); // Pantallas pequeñas
  const isMedium = useMediaQuery(theme.breakpoints.between('sm', 'md')); // Pantallas medianas

  // Función para seleccionar imágenes aleatorias
  const shuffleImages = () => {
    let numberOfImages = 3; // Por defecto, mostramos 3 imágenes

    if (isSmall) {
      numberOfImages = 1; // En pantallas pequeñas, mostrar 1 imagen
    } else if (isMedium) {
      numberOfImages = 2; // En pantallas medianas, mostrar 2 imágenes
    }

    const shuffled = [...allImages].sort(() => 0.5 - Math.random()); // Mezcla las imágenes
    setVisibleImages(shuffled.slice(0, numberOfImages)); // Toma las primeras 'numberOfImages' imágenes
  };

  // Cambia las imágenes cada 5 segundos
  useEffect(() => {
    shuffleImages(); // Llamar la función al cargar la página

    const interval = setInterval(() => {
      shuffleImages();
    }, 5000); // Cambiar cada 5 segundos

    return () => clearInterval(interval); // Limpiar el intervalo cuando el componente se desmonte
  }, [isSmall, isMedium]); // Reejecutar cuando cambie el tamaño de la pantalla

  return (
    <Box sx={{ padding: '40px 20px', textAlign: 'center', backgroundColor: '#ffffff', color: '#000' }}> {/* Fondo blanco */}
      <Typography variant="h4" gutterBottom sx={{ color: '#2fa68e', fontWeight: 'bold' }}> {/* Título con color de productos */}
        GALERÍA
      </Typography>
      <Divider sx={{ width: { xs: '70%', md: '50%' }, margin: '20px auto' }} />
      
      <Grid container spacing={4} justifyContent="center" sx={{ maxWidth: '75%', margin: '0 auto' }}> {/* Centrar las imágenes y limitar al 75% del ancho */}
        {visibleImages.map((image, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Link to="/gallery-full">
              <Box
                component="img"
                src={image}
                alt={`Galería ${index}`}
                sx={{
                  width: '100%',
                  borderRadius: '10px',
                  boxShadow: '0 4px 10px rgba(0, 0, 0, 0.3)',
                  cursor: 'pointer',
                  transition: 'transform 0.3s',
                  '&:hover': {
                    transform: 'scale(1.05)',
                  },
                }}
              />
            </Link>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Gallery;
