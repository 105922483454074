import React from 'react';
import { Box } from '@mui/material';
import whatsAppIcon from '../image/logowha.jpg'; // Asegúrate de que esta ruta sea correcta

const FloatingWhatsAppButton = () => {
  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: 20,
        right: 20,
        zIndex: 1000,
        cursor: 'pointer',
      }}
    >
      <a
        href="https://api.whatsapp.com/send?phone=593988731289&text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n%20sobre%20SAINZA%20."
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={whatsAppIcon}
          alt="WhatsApp"
          style={{
            width: '60px',
            height: '60px',
            borderRadius: '50%',
            boxShadow: '0 4px 8px rgba(0,0,0,0.3)',
          }}
        />
      </a>
    </Box>
  );
};

export default FloatingWhatsAppButton;
