import React, { useRef } from 'react';
import { Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import About from './components/About';
import Benefits from './components/Beneficio';
import Hero from './components/Hero';
import Contact from './components/Contact';
import Gallery from './components/Gallery';
import GalleryFull from './components/GalleryFull';
import Products from './components/Products'; 
import Footer from './components/Footer'; 
import Opinions from './components/Opinions'; 
import FloatingWhatsAppButton from './components/FloatingWhatsAppButton'; // Importar el botón flotante de WhatsApp

const App = () => {
  const aboutRef = useRef(null);
  const benefitsRef = useRef(null);
  const galleryRef = useRef(null);
  const contactRef = useRef(null);
  const productsRef = useRef(null); 
  const opinionsRef = useRef(null); 

  const scrollToAbout = () => {
    aboutRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const scrollToBenefits = () => {
    benefitsRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const scrollToGallery = () => {
    galleryRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const scrollToContact = () => {
    contactRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const scrollToProducts = () => {
    productsRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const scrollToOpinions = () => {
    opinionsRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <>
              <Header
                scrollToAbout={scrollToAbout}
                scrollToBenefits={scrollToBenefits}
                scrollToGallery={scrollToGallery}
                scrollToContact={scrollToContact}
                scrollToProducts={scrollToProducts}
                scrollToOpinions={scrollToOpinions} 
              />
              <Hero scrollToAbout={scrollToAbout} />
              <div ref={aboutRef}>
                <About />
              </div>
              <div ref={benefitsRef}>
                <Benefits />
              </div>
              <div ref={productsRef}>
                <Products />
              </div>
              <div ref={galleryRef}>
                <Gallery />
              </div>
              <div ref={contactRef}>
                <Contact />
              </div>
              <div ref={opinionsRef}>
                <Opinions /> 
              </div>
              <Footer /> 
              <FloatingWhatsAppButton /> {/* Añadir el botón flotante */}
            </>
          }
        />
        <Route path="/gallery-full" element={<GalleryFull />} />
      </Routes>
    </>
  );
};

export default App;
