import React, { useRef, useEffect, useState } from 'react';
import { Container, Grid, Typography, Divider } from '@mui/material';
import logo from '../image/logo.png'; // Ruta correcta para tu logo
import vid1 from '../image/vid1.mp4'; // Ruta correcta para tu video

const About = () => {
  const [textHeight, setTextHeight] = useState(0);
  const textRef = useRef(null);

  useEffect(() => {
    if (textRef.current) {
      setTextHeight(textRef.current.clientHeight); // Ajustar la altura del video con la del texto
    }
  }, []);

  const handleVideoClick = () => {
    window.open('https://www.instagram.com/reel/C_Re2AmAFWW/?igsh=djk2MXpqeHBwempj', '_blank');
  };

  return (
    <Container maxWidth="lg" sx={{ padding: { xs: '40px 20px', md: '60px 0' } }}>
      {/* Logo más grande y responsive */}
      <Grid container justifyContent="center">
        <img src={logo} alt="Logo" style={{ width: '100%', maxWidth: '300px' }} /> {/* Cambié el tamaño del logo */}
      </Grid>

      {/* Título debajo del logo */}
      <Typography variant="h4" align="center" sx={{ marginTop: '20px', color: '#2fa68e', fontWeight: 'bold' }}>
        Salud, Bienestar y Belleza.
      </Typography>

      {/* Línea divisora */}
      <Divider sx={{ width: { xs: '70%', md: '50%' }, margin: '20px auto' }} />

      {/* Sección de texto e imagen/video */}
      <Grid container spacing={4} sx={{ marginTop: '40px' }}>
        <Grid item xs={12} md={6}>
          <div ref={textRef}>
            <Typography variant="body1" paragraph sx={{ textAlign: 'justify', fontSize: { xs: '14px', md: '16px' } }}>
            Sainza, es una empresa comprometida con la seguridad alimentaria y el bienestar, surge de la firme convicción de contribuir al mejoramiento nutricional y fomentar la agricultura sostenible. Nos enorgullece ofrecer productos funcionales teniendo como producto estrella el Sacha Inchi conocido también como maní del Inca.            
            </Typography>
            <Typography variant="body1" paragraph sx={{ textAlign: 'justify', fontSize: { xs: '14px', md: '16px' } }}>
            Considerado un superalimento, el Sacha Inchi es notable por sus altos contenidos de Omega-3, antioxidantes y proteínas, ofreciendo múltiples beneficios para la salud cardiovascular, cerebral, la piel y el cabello
            </Typography>
            <Typography variant="body1" paragraph sx={{ textAlign: 'justify', fontSize: { xs: '14px', md: '16px' } }}>
            Cultivado con esmero en el fértil suelo del Alto Nangaritza y bajo estrictos sistemas de la agricultura orgánica y sostenible , no solo garantizamos la calidad de nuestros productos, sino que también fortalecemos la identidad cultural y el desarrollo económico de la región. 
            </Typography>
            <Typography variant="body1" paragraph sx={{ textAlign: 'justify', fontSize: { xs: '14px', md: '16px' } }}>
            En Sainza, nos esforzamos por ser agentes de cambio, apostando por la seguridad alimentaria, y la sostenibilidad ambiental. 
            </Typography>
            <Typography variant="body1" paragraph sx={{ textAlign: 'justify', fontSize: { xs: '14px', md: '16px' } }}>
            Descubre más sobre nuestros productos y todo el beneficio que estos ofrecen a la salud y bienestar integral.
            </Typography>
          </div>
        </Grid>

        {/* Video en bucle ajustado para mostrar completo en formato vertical */}
        <Grid item xs={12} md={6} sx={{ textAlign: 'center', display: 'flex', justifyContent: 'center' }}>
          <video
            autoPlay
            muted
            loop
            onClick={handleVideoClick} // Al hacer clic redirige a la URL de Instagram
            style={{
              objectFit: 'contain', // Asegura que el video se ajuste dentro del contenedor sin recortarse
              height: `${textHeight}px`, // Ajustar la altura del video al texto
              borderRadius: '10px', // Bordes redondeados
              cursor: 'pointer', // Indica que el video es clicable
              width: '100%', // Asegura que el video se ajuste al contenedor
              maxWidth: '400px', // Limita el ancho del video para mantenerlo proporcional
            }}
          >
            <source src={vid1} type="video/mp4" />
            Tu navegador no soporta el elemento de video.
          </video>
        </Grid>
      </Grid>
    </Container>
  );
};

export default About;
