import React, { useState } from 'react';
import {
  Box, Grid, Typography, Divider, Modal, IconButton, Accordion, AccordionSummary, AccordionDetails, Button,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';
import aceite from '../image/aceite.png';
import proteina from '../image/proteina.png';

const productsData = [
  {
    name: 'Aceite de Sacha Inchi (250 ml)',
    image: aceite,
    description: 'Contiene un 87% de ácidos grasos Omega 3 y 6, vitaminas A y E.',
    properties: [
      'Rico en Omega 3 y 6: Favorece la salud cardiovascular.',
      'Minerales esenciales: Magnesio y potasio.',
      'Vitaminas A y E: Antioxidantes para piel y cabello.',
    ],
    benefits: [
      'Salud Cardiovascular: Niveles saludables de colesterol.',
      'Antiinflamatorio: Alivia condiciones como la artritis.',
      'Cuidado de la Piel y el Cabello: Hidrata y fortalece.',
    ],
    consumption: ['10 ml en la mañana o noche.', 'Ideal para ensaladas y batidos.'],
  },
  {
    name: 'Proteína Vegetal de Sacha Inchi (500 gr)',
    image: proteina,
    description: '32% de proteína de alta calidad para la recuperación muscular.',
    benefits: [
      'Desarrollo Muscular: Ideal para deportistas.',
      'Digestión Suave: Apto para todas las edades.',
      'Proteína Completa: Contiene todos los aminoácidos esenciales.',
    ],
    consumption: ['Dos cucharadas antes o después de entrenar.'],
  },
];

const Products = () => {
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  const handleOpen = (index) => {
    setSelectedProduct(index);
    setModalOpen(true);
    setTimeout(() => {
      const el = document.getElementById(`accordion-product-${index}`);
      el && el.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }, 300);
  };

  const handleClose = () => {
    setModalOpen(false);
    setSelectedProduct(null);
  };

  return (
    <Box sx={{ padding: '50px 20px', backgroundColor: '#f5f5f5', textAlign: 'center' }}>
      <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold', color: '#2fa68e' }}>
        Nuestros Productos
      </Typography>

      <Divider sx={{ width: { xs: '70%', md: '50%' }, margin: '20px auto', backgroundColor: '#2fa68e' }} />

      {/* Pantallas Grandes */}
      <Box sx={{ display: { xs: 'none', md: 'block' }, marginTop: '20px' }}>
        <Grid container spacing={4} justifyContent="center" alignItems="center">
          {productsData.map((product, index) => (
            <Grid
              item
              xs={12}
              sm={6}
              key={index}
              sx={{
                display: 'flex',
                justifyContent: selectedProduct === index ? 'flex-start' : 'center',
                alignItems: 'center',
                cursor: 'pointer',
                transition: 'all 0.5s ease',
                width: selectedProduct === index ? '100%' : '50%',
                zIndex: selectedProduct === index ? 2 : 1,
              }}
            >
              {selectedProduct === index ? (
                <Box
                  sx={{
                    width: '100%',
                    padding: '20px',
                    backgroundColor: '#fff',
                    borderRadius: '10px',
                    boxShadow: '0px 4px 20px rgba(0,0,0,0.1)',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <Box
                    component="img"
                    src={product.image}
                    alt={product.name}
                    sx={{
                      width: '350px',
                      height: 'auto',
                      objectFit: 'contain',
                      transition: 'transform 0.5s ease',
                    }}
                  />
                  <Box sx={{ textAlign: 'left', padding: '20px', flex: 1 }}>
                    <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#333' }}>
                      {product.name}
                    </Typography>
                    <Typography variant="body1" sx={{ color: '#555', marginBottom: '10px' }}>
                      {product.description}
                    </Typography>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                      Propiedades:
                    </Typography>
                    <ul>
                      {product.properties?.map((property, i) => (
                        <li key={i}>
                          <Typography variant="body2" sx={{ color: '#555' }}>
                            {property}
                          </Typography>
                        </li>
                      ))}
                    </ul>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                      Beneficios:
                    </Typography>
                    <ul>
                      {product.benefits?.map((benefit, i) => (
                        <li key={i}>
                          <Typography variant="body2" sx={{ color: '#555' }}>
                            {benefit}
                          </Typography>
                        </li>
                      ))}
                    </ul>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                      ¿Cómo consumirlo?:
                    </Typography>
                    <ul>
                      {product.consumption?.map((method, i) => (
                        <li key={i}>
                          <Typography variant="body2" sx={{ color: '#555' }}>
                            {method}
                          </Typography>
                        </li>
                      ))}
                    </ul>
                  </Box>
                </Box>
              ) : (
                <Box sx={{ textAlign: 'center' }}>
                  <Box
                    component="img"
                    src={product.image}
                    alt={product.name}
                    sx={{
                      width: '250px',
                      height: 'auto',
                      objectFit: 'contain',
                      transition: 'transform 0.5s ease',
                    }}
                  />
                  <Typography variant="body1" sx={{ color: '#333', marginTop: '10px', padding: '0 10px' }}>
                    {product.description}
                  </Typography>
                  <Button
                    variant="contained"
                    sx={{
                      marginTop: '10px',
                      backgroundColor: '#2fa68e',
                      color: '#fff',
                      '&:hover': { backgroundColor: '#248d71' },
                    }}
                    onClick={() => setSelectedProduct(index)}
                  >
                    Ver más
                  </Button>
                </Box>
              )}
            </Grid>
          ))}
        </Grid>
      </Box>

      {/* Pantallas Pequeñas */}
      <Box sx={{ display: { xs: 'block', md: 'none' }, marginTop: '20px' }}>
        <Grid container spacing={4} justifyContent="center">
          {productsData.map((product, index) => (
            <Grid item xs={12} sm={6} key={index} onClick={() => handleOpen(index)}>
              <Box sx={{ textAlign: 'center', cursor: 'pointer' }}>
                <Box
                  component="img"
                  src={product.image}
                  alt={product.name}
                  sx={{
                    width: '200px',
                    height: 'auto',
                    objectFit: 'contain',
                  }}
                />
                <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#333', marginTop: '10px' }}>
                  {product.name}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>

        {/* Modal para pantallas pequeñas */}
        <Modal open={modalOpen} onClose={handleClose}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              backgroundColor: '#fff',
              padding: '20px',
              borderRadius: '10px',
              maxWidth: '90%',
              width: '400px',
              maxHeight: '90vh',
              overflowY: 'auto',
              boxShadow: 24,
            }}
          >
            <IconButton
              onClick={handleClose}
              sx={{ position: 'absolute', top: '10px', right: '10px', color: '#333' }}
            >
              <CloseIcon />
            </IconButton>
            {selectedProduct !== null && (
              <>
                <Box
                  component="img"
                  src={productsData[selectedProduct].image}
                  alt={productsData[selectedProduct].name}
                  sx={{ width: '100%', height: 'auto', objectFit: 'contain', marginBottom: '20px' }}
                />
                <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
                  {productsData[selectedProduct].name}
                </Typography>
                <Typography variant="body1" sx={{ color: '#555', marginBottom: '10px' }}>
                  {productsData[selectedProduct].description}
                </Typography>

                {/* Acordeones para Propiedades, Beneficios y Consumo */}
                <Accordion id={`accordion-product-${selectedProduct}`}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Propiedades</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <ul>
                      {productsData[selectedProduct].properties?.map((property, i) => (
                        <li key={i}>
                          <Typography variant="body2" sx={{ color: '#555' }}>
                            {property}
                          </Typography>
                        </li>
                      ))}
                    </ul>
                  </AccordionDetails>
                </Accordion>

                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Beneficios</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <ul>
                      {productsData[selectedProduct].benefits?.map((benefit, i) => (
                        <li key={i}>
                          <Typography variant="body2" sx={{ color: '#555' }}>
                            {benefit}
                          </Typography>
                        </li>
                      ))}
                    </ul>
                  </AccordionDetails>
                </Accordion>

                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>¿Cómo consumirlo?</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <ul>
                      {productsData[selectedProduct].consumption?.map((method, i) => (
                        <li key={i}>
                          <Typography variant="body2" sx={{ color: '#555' }}>
                            {method}
                          </Typography>
                        </li>
                      ))}
                    </ul>
                  </AccordionDetails>
                </Accordion>
              </>
            )}
          </Box>
        </Modal>
      </Box>
    </Box>
  );
};

export default Products;
